<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<pdf :src="pdf_url_1"></pdf>
			<pdf :src="pdf_url_2"></pdf>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	import ProdSpec from "@/components/ProdSpec.vue";
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'KS8223',
		components: {
			PageBot,
			PageNav,
			ProdSpec,
			pdf
		},
		created() {},
		watch: {

		},

		mounted() {

		},
		data() {
			return {
				pdf_url_1:this.api_base_url + "/offical-img/pdf/ZT8303/pg1.pdf",
				pdf_url_2:this.api_base_url + "/offical-img/pdf/ZT8303/pg2.pdf",
				avtive_nav: "",
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		// height: 530vh;
		// object-fit: cover; // 保持图片缩放时候不变形
		// background-image: url("../../assets/images/prods/zt6250/pg1.png");
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center center;
		// -webkit-user-select: none;
		// -moz-user-select: none;
		// -ms-user-select: none;
	}
	.page_2{
		width: 100%;
		// height: 265vh;
		// object-fit: cover; // 保持图片缩放时候不变形
		// background-image: url("../../assets/images/prods/zt6250/pg2.png");
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center center;
		// -webkit-user-select: none;
		// -moz-user-select: none;
		// -ms-user-select: none;
	}




	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
</style>